.EyeIconContainer {
  position: absolute;
  /* right: 2rem; */
  top: 50%;
  transform: translate(0, -50%);
  background: none;
  border: none;
  padding: 0.5rem;
  margin: 0;
  font-size: 1.5rem;
}
