.FileDropArea {
  position: relative;
  width: 100%;
  padding: 4rem 2rem;
  border: 2px dashed #999;
  border-radius: 3px;
  transition: 0.2s;
  text-align: center;
}

.FileMessage {
}

.FileInput {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
