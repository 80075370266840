.LoginCard {
  padding: 4rem 1rem;
  border: 2px solid #888;
  border-radius: 1rem;
}

@media only screen and (min-width: 576px) {
  .LoginCard {
    padding: 4rem;
    border: 2px solid #888;
    border-radius: 1rem;
  }
}
